<script setup>
import MenuItem from '@/Layouts/Partials/MenuItem.vue';
import NavigationButtons from '@/Pages/Public/Partials/NavigationButtons.vue';
import { usePage } from '@inertiajs/vue3';
import { computed, inject } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
    open: Boolean,
    locale: String,
    locales: Array,
    otherLocale: String,
    pageTemplate: String,
});
const { t } = useI18n();
const emits = defineEmits(['clickLocale', 'update:open']);
const route = inject('route');
const locale = computed(() => usePage().props.locale);

const currentPage = usePage().props.route.current;
const isEmployerPage = computed(() => !!currentPage.includes(route('public.pages.show', { page: t('employers') })));
</script>

<template>
    <div
        :class="{ '!translate-y-0': open }"
        class="fixed top-0 left-0 right-0 z-40 h-full transition-transform duration-300 -translate-y-full bg-blue overflow-hidden"
    >
        <div class="h-30"></div>
        <ul class="flex flex-col mx-10 mb-2">
            <li class="flex justify-end py-4 border-t border-gray-purple" v-if="isEmployerPage">
                <MenuItem :href="route('login')" :text="$t('Log in')" v-if="!$page.props?.user_id" />
                <MenuItem :href="route('logout')" :text="$t('Log out')" method="POST" v-else />
            </li>
            <li class="flex justify-end py-4 border-t border-gray-purple">

            <MenuItem v-if="!isEmployerPage && locale === 'nl'"
                      :href="route('public.job_offers.index')"
                      :text="$t('Job offers')"
            />
            <MenuItem v-else-if="locale === 'nl'"
                      :href="route('place-job-offer.index')"
                      :text="$t('Place job offer')"
            />
            </li>

            <li class="flex justify-end py-4 border-t border-gray-purple">
                <MenuItem
                    :href="route('faqs', { 'currentCategories[]': isEmployerPage ? 'employer' : 'flexer' })"
                    :text="$t('FAQ')"
                />
            </li>
            <li class="flex justify-end py-4 border-t border-gray-purple">
                <MenuItem
                    :href="route('blogs.show', locale === 'en' ? 'about-flexable' : 'over-flexable')"
                    :text="$t('About us')"
                />
            </li>
            <li class="flex justify-end py-4 border-t border-gray-purple">
                <MenuItem :text="$t('contact')" :href="route('contact')" />
            </li>
            <li class="flex justify-end py-4 border-t border-gray-purple">
                <MenuItem :href="route('blogs.index')" :text="$t('Blog')" />
            </li>

            <li class="flex justify-end py-4 border-t border-gray-purple" v-if='locales?.length > 1'>
                <div class='h-8 pl-4 flex items-center'>
                    <template v-for='locale in locales'>
                        <a class="block uppercase" :href='locale.value' v-if='locale.key === otherLocale'>
                            {{ locale.key }}
                        </a>
                    </template>
                </div>
            </li>
        </ul>

        <div class="flex flex-col gap-4 mx-10 mb-6 text-center" v-if="pageTemplate != 'landingspage'">
            <NavigationButtons fullWidth />
        </div>
    </div>
</template>
