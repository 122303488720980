<script setup>
import { Head, Link, usePage } from '@inertiajs/vue3';
import { onClickOutside } from '@vueuse/core';
import { computed, inject, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import MobileMenu from '@/Layouts/Partials/MobileMenu.vue';
import menuCloseIcon from '@i/menu-close.svg';
import menuIcon from '@i/menu.svg';

// Components
import MenuItem from '@/Layouts/Partials/MenuItem.vue';
import Footer from '@/Pages/Public/Partials/Footer.vue';
import NavigationButtons from '@/Pages/Public/Partials/NavigationButtons.vue';

const i18n = useI18n();
const route = inject('route');
const setZiggyLocale = inject('setZiggyLocale');
const setDayjsLocale = inject('setDayjsLocale');

defineProps({
    pageTemplate: String,
    title: String,
});

const locale = computed(() => usePage().props.locale);
const otherLocale = computed(() => (usePage().props.locale == 'nl' ? 'en' : 'nl'));

const locales = computed(() => {
    return Object.entries(usePage().props.route.locale)
        .map(([key, value]) => ({ key, value }))
        .filter((locale) => locale.value);
});

const currentPage = usePage().props.route.current;

const isEmployerPage = computed(
    () => !!currentPage.includes(route('public.pages.show', { page: i18n.t('employers') })) || !!currentPage.includes(route('public.pages.show', { page: 'employers-alt' })) || !!currentPage.includes(route('public.pages.show', { page: 'werkgevers-alt' })),
);

const showingMobileMenu = ref(false);
const mobileMenu = ref(null);
const scrollPosition = ref(null);

onClickOutside(mobileMenu, () => (showingMobileMenu.value = false));

const updateScroll = () => {
    scrollPosition.value = window.scrollY;
};

onMounted(() => {
    window.addEventListener('scroll', updateScroll);
});
</script>

<template>
    <div>
        <Head :title="title" />
        <div class="force-scroll relative flex flex-col text-white">
            <!-- Page Heading -->
            <header class="fixed left-0 top-0 z-50 w-full bg-blue shadow">
                <!--                <GradientBar-->
                <!--                    v-if="pageTemplate != 'landingspage'"-->
                <!--                    :text="-->
                <!--                        $t('The platform for {bold} in the supermarket', {-->
                <!--                            bold: `<strong>${$t('Flexible work')}</strong>`,-->
                <!--                        })-->
                <!--                    "-->
                <!--                />-->

                <div
                    :class="{ 'transition-all duration-300 xl:py-3': scrollPosition > 200 }"
                    class="flex items-center justify-between gap-5 px-4 xl:min-h-20 xl:p-10"
                >
                    <div>
                        <Link :href="route('home')">
                            <img
                                class="h-auto w-[100px] xl:w-[245px]"
                                src="@/../img/flexable-logo-sunset.svg"
                                alt="Flexable logo"
                                :class="{ 'scale-75 transition-all duration-300': scrollPosition > 200 }"
                            >
                        </Link>
                    </div>
                    <div class="hidden items-center xl:flex">
                        <div class="ml-2 mr-6 flex flex-wrap gap-x-8 gap-y-4">
                            <!--                            <MenuItem :href="route('shifts.index')" :text="$t('Shifts')" />-->

                            <MenuItem
                                :href="route('blogs.index')"
                                :text="$t('Blog')"
                            />
                            <MenuItem
                                :href="route('contact')"
                                :text="$t('Contact')"
                            />
                            <MenuItem
                                :href="route('blogs.show', locale === 'en' ? 'about-flexable' : 'over-flexable')"
                                :text="$t('About us')"
                            />
                            <MenuItem
                                :href="route('faqs', { 'currentCategories[]': isEmployerPage ? 'employer' : 'flexer' })"
                                :text="$t('FAQ')"
                            />

                            <MenuItem
                                v-if="!isEmployerPage && locale === 'nl'"
                                :href="route('public.job_offers.index')"
                                :text="$t('Job offers')"
                            />
                            <MenuItem
                                v-else-if="locale === 'nl'"
                                :href="route('place-job-offer.index')"
                                :text="$t('Place job offer')"
                            />
                            <MenuItem
                                v-if="!$page.props?.user_id && isEmployerPage"
                                :href="route('login')"
                                :text="$t('Log in')"
                            />
                            <MenuItem
                                v-if="$page.props?.user_id && isEmployerPage"
                                :href="route('logout')"
                                :text="$t('Log out')"
                                method="POST"
                            />
                        </div>

                        <div
                            v-if="locales?.length > 1"
                            class="flex h-8 items-center border-l border-white/60 pl-4 uppercase text-white"
                        >
                            <template v-for="locale in locales">
                                <a
                                    v-if="locale.key === otherLocale"
                                    :href="locale.value"
                                >
                                    {{ locale.key }}
                                </a>
                            </template>
                        </div>


                        <div
                            v-if="pageTemplate != 'landingspage'"
                            class="flex gap-4 lg:ml-9"
                        >
                            <NavigationButtons />
                        </div>
                    </div>

                    <button class="relative m-3 mr-0 flex h-5 w-5 items-center justify-center xl:hidden">
                        <img
                            class="absolute transition-opacity duration-300"
                            :class="{ 'pointer-events-none opacity-0': showingMobileMenu }"
                            :src="menuIcon"
                            alt="open menu"
                            @click="showingMobileMenu = true"
                        >
                        <img
                            class="absolute transition-opacity duration-300"
                            :class="{ 'pointer-events-none opacity-0': !showingMobileMenu }"
                            :src="menuCloseIcon"
                            alt="close menu"
                            @click="showingMobileMenu = false"
                        >
                    </button>
                </div>
            </header>
            <div class="h-20 md:h-40 xl:h-51" />
            <!-- End Page Header -->
            <MobileMenu
                ref="mobileMenu"
                v-model:open="showingMobileMenu"
                :locales="locales"
                :locale="locale"
                :other-locale="otherLocale"
                :page-template="pageTemplate"
            />

            <!-- Page Content -->
            <main class="mb-auto pt-10 md:pt-0">
                <slot />
            </main>
        </div>
        <Footer />
    </div>
</template>
